"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.query = exports.mutation = exports.getAddFavoriteMutation = exports.default = exports.InactiveHeart = exports.InactiveFavoriteButton = exports.ActiveHeart = exports.ActiveFavoriteButton = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _client = require("@apollo/client");
var _graphql = require("../generated/graphql");
var _react = _interopRequireWildcard(require("react"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Heart = _interopRequireDefault(require("./svg/Heart.svg"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _withAuth = _interopRequireDefault(require("../hoc/withAuth"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _LoginContent = _interopRequireDefault(require("./LoginContent"));
var _TenantContext = require("../context/TenantContext");
var _apollo = require("../apollo");
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _router = require("next/router");
var _queryString = require("query-string");
var _lodash = _interopRequireDefault(require("lodash.omit"));
var _Toast = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/feedback/Toast"));
var _reactToastify = require("react-toastify");
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _RegisterContent = _interopRequireDefault(require("./RegisterContent"));
const _excluded = ["type", "id", "isAuthorized", "ActiveButtonComponent", "InactiveButtonComponent"],
  _excluded2 = ["loading"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const query = exports.query = (0, _client.gql)`
    query FavoriteButton {
        viewer {
            favorites {
                __typename
                id
                targetId
            }
        }
    }
`;
const mutation = exports.mutation = (0, _client.gql)`
    mutation addFavoriteActivity($input: AddFavoriteActivityInput!) {
        result: addFavoriteActivity(input: $input) {
            favorite {
                ...MyFavorites
            }
        }
    }
    mutation addFavoriteCompany($input: AddFavoriteCompanyInput!) {
        result: addFavoriteCompany(input: $input) {
            favorite {
                ...MyFavorites
            }
        }
    }
    mutation addFavoriteRentalUnit($input: AddFavoriteRentalUnitInput!) {
        result: addFavoriteRentalUnit(input: $input) {
            favorite {
                ...MyFavorites
            }
        }
    }
    mutation removeFavorite($input: RemoveFavoriteInput!) {
        removeFavorite(input: $input) {
            success
        }
    }
`;
const getAddFavoriteMutation = type => {
  switch (type) {
    case 'company':
      return _graphql.AddFavoriteCompanyDocument;
    case 'activity':
      return _graphql.AddFavoriteActivityDocument;
    case 'rentalUnit':
      return _graphql.AddFavoriteRentalUnitDocument;
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(type);
  }
};
exports.getAddFavoriteMutation = getAddFavoriteMutation;
const FavoriteButtonInner = _ref => {
  let {
      type,
      id,
      isAuthorized,
      ActiveButtonComponent = ActiveFavoriteButton,
      InactiveButtonComponent = InactiveFavoriteButton
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    data
  } = (0, _graphql.useFavoriteButtonQuery)({
    // should already have been fetched by the higher-level withAuth hoc
    fetchPolicy: 'cache-only'
  });
  const landingUrl = props.landingUrl ?? `${window?.location.origin + window?.location.pathname}#addToFavorites`;
  const isFavorite = (0, _react.useMemo)(() => data?.viewer?.favorites.some(favorite => {
    switch (type) {
      case 'company':
        return favorite.__typename === 'FavoriteCompany' && favorite.targetId === id;
      case 'activity':
        return favorite.__typename === 'FavoriteActivity' && favorite.targetId === id;
      case 'rentalUnit':
        return favorite.__typename === 'FavoriteRentalUnit' && favorite.targetId === id;
      default:
        throw new _UnreachableCaseError.UnreachableCaseError(type);
    }
  }), [data?.viewer?.favorites, id, type]);
  const {
    open,
    onClose,
    onOpen
  } = (0, _useSesame.default)();
  const favoriteId = (0, _react.useMemo)(() => data?.viewer?.favorites.find(fav => fav.targetId === id)?.id, [data?.viewer?.favorites, id]);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const client = (0, _client.useApolloClient)();
  const [addFavorite, {
    loading: addFavoriteLoading
  }] = (0, _apollo.useMutation)(getAddFavoriteMutation(type), {
    errorPolicy: 'ignore'
  });
  const [removeFavorite, {
    loading: removeFavoriteLoading
  }] = (0, _graphql.useRemoveFavoriteMutation)({
    errorPolicy: 'ignore'
  });
  const loading = addFavoriteLoading || removeFavoriteLoading;
  const onRemove = (0, _react.useCallback)(async () => {
    try {
      await removeFavorite({
        variables: {
          input: {
            favoriteId: favoriteId
          }
        }
      });
      client.writeQuery({
        query: _graphql.FavoriteButtonDocument,
        variables: {},
        data: {
          __typename: 'Query',
          viewer: {
            __typename: 'Customer',
            favorites: data.viewer.favorites.filter(fav => fav.id !== favoriteId)
          }
        }
      });
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error(ex);
      (0, _reactToastify.toast)(__jsx(_Toast.default, {
        title: __jsx(_reactIntl.FormattedMessage, {
          id: "IWsTaw",
          defaultMessage: [{
            "type": 0,
            "value": "Favoriet verwijderen mislukt"
          }]
        }),
        variant: "error"
      }, __jsx(_Body.default, {
        variant: "small"
      }, __jsx(_reactIntl.FormattedMessage, {
        id: "/gqjo+",
        defaultMessage: [{
          "type": 0,
          "value": "Probeer het later opnieuw"
        }]
      }))), {
        autoClose: 3000
      });
    }
  }, [client, data, favoriteId, removeFavorite]);
  const onAdd = (0, _react.useCallback)(async () => {
    try {
      const result = await addFavorite({
        variables: (() => {
          switch (type) {
            case 'activity':
              return {
                input: {
                  activityId: id
                }
              };
            case 'company':
              return {
                input: {
                  companyId: id
                }
              };
            case 'rentalUnit':
              return {
                input: {
                  rentalUnitId: id
                }
              };
            default:
              throw new _UnreachableCaseError.UnreachableCaseError(type);
          }
        })()
      });
      if (result.data?.result.favorite?.id) {
        // update cache ourselves, so we don't have to make a roundtrip to the server again
        client.writeQuery({
          query: _graphql.FavoriteButtonDocument,
          variables: {},
          data: {
            __typename: 'Query',
            viewer: {
              __typename: 'Customer',
              favorites: [...data.viewer.favorites, {
                __typename: (() => {
                  switch (type) {
                    case 'activity':
                      return 'FavoriteActivity';
                    case 'company':
                      return 'FavoriteCompany';
                    case 'rentalUnit':
                      return 'FavoriteRentalUnit';
                    default:
                      throw new _UnreachableCaseError.UnreachableCaseError(type);
                  }
                })(),
                id: result.data.result.favorite.id,
                targetId: id
              }]
            }
          }
        });
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error(ex);
      (0, _reactToastify.toast)(__jsx(_Toast.default, {
        title: __jsx(_reactIntl.FormattedMessage, {
          id: "1s60rZ",
          defaultMessage: [{
            "type": 0,
            "value": "Favoriet toevoegen mislukt"
          }]
        }),
        variant: "error"
      }, __jsx(_Body.default, {
        variant: "small"
      }, __jsx(_reactIntl.FormattedMessage, {
        id: "/gqjo+",
        defaultMessage: [{
          "type": 0,
          "value": "Probeer het later opnieuw"
        }]
      }))), {
        autoClose: 3000
      });
    }
  }, [addFavorite, type, id, client, data]);
  const onClick = (0, _react.useCallback)(() => {
    if (!isAuthorized) {
      onOpen();
    } else if (isFavorite) {
      onRemove();
    } else {
      onAdd();
    }
  }, [isAuthorized, isFavorite, onAdd, onOpen, onRemove]);
  const ButtonComponent = isFavorite ? ActiveButtonComponent : InactiveButtonComponent;
  const router = (0, _router.useRouter)();
  (0, _react.useEffect)(() => {
    if (router.query.addToFavorites && !isFavorite) {
      router.replace({
        pathname: router.pathname,
        query: (0, _lodash.default)(router.query, 'addToFavorites')
      }, undefined, {
        shallow: true
      });
      onAdd();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  const {
    0: isLogin,
    1: setIsLogin
  } = (0, _react.useState)(false);
  const ModalContent = isLogin ? StyledLoginContent : StyledRegisterContent;
  return __jsx(_react.default.Fragment, null, __jsx(ButtonComponent, {
    onClick: onClick,
    submitting: loading,
    disabled: loading
  }), __jsx(_Modal.default, {
    open: open,
    onClose: onClose,
    variant: "regular"
  }, __jsx(ModalContent, {
    onToggle: () => setIsLogin(login => !login),
    title: isLogin ? __jsx(_reactIntl.FormattedMessage, {
      id: "aXLE6j",
      defaultMessage: [{
        "type": 0,
        "value": "Mijn "
      }, {
        "type": 1,
        "value": "island"
      }],
      values: {
        island: brandConfig.name
      }
    }) : __jsx(_reactIntl.FormattedMessage, {
      id: "YAIOLM",
      defaultMessage: [{
        "type": 0,
        "value": "Sla favorieten op voor later"
      }],
      values: {
        island: brandConfig.name
      }
    }),
    description: isLogin ? __jsx(_reactIntl.FormattedMessage, {
      id: "3ohpxr",
      defaultMessage: [{
        "type": 0,
        "value": "Bekijk uw reservering(en), doe een betaling, print uw voucher of geef een beoordeling op uw verblijf."
      }]
    }) : __jsx(_reactIntl.FormattedMessage, {
      id: "7/0ZcF",
      defaultMessage: [{
        "type": 0,
        "value": "Maak direct een Mijn "
      }, {
        "type": 1,
        "value": "island"
      }, {
        "type": 0,
        "value": " account aan. Zonder wachtwoord, gewoon met je e-mailadres."
      }],
      values: {
        island: brandConfig.name,
        br: __jsx("br", null)
      }
    })
    // helperText={
    //     process.env.NEXT_PUBLIC_BRAND === 'texel' && (
    //         <FormattedMessage defaultMessage="* Als cadeautje krijg je direct de PDF brochure Evenementen op Texel 2023" />
    //     )
    // }
    ,
    landingUrl: landingUrl,
    brandName: brandConfig.name
  })));
};
const FavoriteButtonOuter = _ref2 => {
  let {
      loading
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  const SpinnerComponent = props.Spinner ?? Spinner;
  if (loading) {
    return __jsx(SpinnerComponent, null);
  }
  return __jsx(FavoriteButtonInner, props);
};
const ActiveFavoriteButton = props => __jsx(_Button.default, (0, _extends2.default)({
  variant: "outline"
}, props), __jsx(ActiveHeart, null), __jsx("span", {
  className: "gt-s"
}, __jsx(_reactIntl.FormattedMessage, {
  id: "J1TUIg",
  defaultMessage: [{
    "type": 0,
    "value": "Opgeslagen"
  }]
})));
exports.ActiveFavoriteButton = ActiveFavoriteButton;
const InactiveFavoriteButton = props => __jsx(_Button.default, (0, _extends2.default)({
  variant: "outline"
}, props), __jsx(InactiveHeart, null), __jsx("span", {
  className: "gt-s"
}, __jsx(_reactIntl.FormattedMessage, {
  id: "Sxhdhg",
  defaultMessage: [{
    "type": 0,
    "value": "Opslaan"
  }]
})));
exports.InactiveFavoriteButton = InactiveFavoriteButton;
const Spinner = () => __jsx(InactiveFavoriteButton, {
  disabled: true,
  style: {
    cursor: 'progress'
  }
});
const FavoriteButtonWithAuth = (0, _withAuth.default)(FavoriteButtonOuter, {
  withLoadingProp: true,
  authQuery: _graphql.FavoriteButtonDocument,
  onFail: () => 'ignore'
});
const FavoriteButton = props => {
  const router = (0, _router.useRouter)();
  (0, _react.useEffect)(() => {
    (async () => {
      // fix url when we're getting here from a login email.
      // hacky, but no way around this without modifying how the backend interprets our landingUrl
      if (typeof window !== 'undefined' && window.location.hash.startsWith('#addToFavorites')) {
        await router.replace({
          pathname: router.pathname,
          query: _objectSpread(_objectSpread(_objectSpread({}, router.query), (0, _queryString.parse)(window.location.hash.replace('#addToFavorites', ''))), {}, {
            addToFavorites: 1
          })
        }, undefined, {
          shallow: true
        });
        setShowSpinner(false);
      }
    })();
  }, [router]);
  const {
    0: showSpinner,
    1: setShowSpinner
  } = (0, _react.useState)(typeof window !== 'undefined' && window.location.hash.startsWith('#addToFavorites'));
  const SpinnerComponent = props.Spinner ?? Spinner;
  return showSpinner ? __jsx(SpinnerComponent, null) : __jsx(FavoriteButtonWithAuth, props);
};
const StyledLoginContent = (0, _styledComponents.default)(_LoginContent.default).withConfig({
  displayName: "FavoriteButton__StyledLoginContent",
  componentId: "sc-1hqgl6i-0"
})(["max-width:unset;"]);
const StyledRegisterContent = (0, _styledComponents.default)(_RegisterContent.default).withConfig({
  displayName: "FavoriteButton__StyledRegisterContent",
  componentId: "sc-1hqgl6i-1"
})(["max-width:unset;"]);
const StyledHeart = (0, _styledComponents.default)(_Heart.default).withConfig({
  displayName: "FavoriteButton__StyledHeart",
  componentId: "sc-1hqgl6i-2"
})(["margin-left:-1.5rem;margin-right:-2.4rem;@media screen and (min-width:", "){margin-right:0.7rem;margin-left:-1.2rem;}"], ({
  theme
}) => theme.mediaQueries.s);
const ActiveHeart = exports.ActiveHeart = (0, _styledComponents.default)(StyledHeart).withConfig({
  displayName: "FavoriteButton__ActiveHeart",
  componentId: "sc-1hqgl6i-3"
})(["color:", ";"], ({
  theme
}) => theme.colors.negative[50]);
const InactiveHeart = exports.InactiveHeart = (0, _styledComponents.default)(StyledHeart).withConfig({
  displayName: "FavoriteButton__InactiveHeart",
  componentId: "sc-1hqgl6i-4"
})(["color:rgba(0,0,0,0.33);"]);
var _default = exports.default = FavoriteButton;